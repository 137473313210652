<template>
    <div
        ref="block"
        :is="tag"
        class="fade-in-animation"
        :class="{ animated: isAnimated }"
        :style="{ animationDelay: delay + 's', animationDuration: duration }"
    >
        <slot></slot>
    </div>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default {
    name: 'FadeIn',
    props: {
        duration: {
            type: Number,
            default: 2,
        },
        delay: {
            type: Number,
            default: 0.3,
        },
        tag: {
            type: String,
            default: 'div',
        },
    },
    data() {
        return {
            isAnimated: false,
        }
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger)

        gsap.to(this.$refs.block, {
            scrollTrigger: {
                trigger: this.$refs.block,
                start: 'top 90%',
                onEnter: () => (this.isAnimated = true),
                once: true,
            },
        })
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.fade-in-animation {
    @media (max-width: 767px) {
        opacity: 1;
        visibility: visible;
    }
    opacity: 0;
    visibility: hidden;
    animation: 1s 0.3s ease-in-out forwards;
}
.animated {
    animation-name: imgOverlay;
}
@keyframes imgOverlay {
    100% {
        opacity: 1;
        visibility: visible;
    }
}
</style>
